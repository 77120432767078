import { Controller } from "@hotwired/stimulus";
import { FetchRequest, get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["email", "emailContainer", "defaultContainer"];

  connect() {}

  showEmailField(event) {
    event.preventDefault();
    this.defaultContainerTarget.classList.add("d-none");
    this.emailContainerTarget.classList.remove("d-none");
  }

  hideEmailField(event) {
    event.preventDefault();
    this.emailTarget.value = "";
    this.emailContainerTarget.classList.add("d-none");
    this.defaultContainerTarget.classList.remove("d-none");
  }
}
