import { Controller } from "@hotwired/stimulus";
import { FetchRequest, get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["notify", "iosfixEmails", "iosfixEmailsContainer"];

  connect() {
    this.notifyChange();
  }

  notifyChange(event) {
    let notifyValue = "";
    if (this.hasIosfixEmailsContainerTarget) {
      this.notifyTargets.forEach((e) => {
        if (e.checked) {
          notifyValue = e.value;
        }
      });
      console.log(notifyValue);
      let container = this.iosfixEmailsContainerTarget;
      if (notifyValue == "iosfix") {
        container.classList.remove("d-none");
      } else {
        container.classList.add("d-none");
      }
    }
  }
}
