import { Controller } from "@hotwired/stimulus";
import { FetchRequest, get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "patientId",
    "patientIdContainer",
    "loading",
    "patientCode",
    "patientFirstName",
    "patientLastName",
    "patientSearchButton",
  ];
  static values = {
    url: String,
    param: String,
  };

  connect() {}

  togglePatientSearch(event) {
    if (this.hasPatientIdContainerTarget) {
      let patientIdContainer = this.patientIdContainerTarget;
      let patientSearchButton = this.patientSearchButtonTarget;
      if (patientIdContainer.classList.contains("opacity-0")) {
        // patientIdContainer.classList.remove("d-none");
        patientIdContainer.classList.remove("opacity-0");
        patientSearchButton.classList.add("btn-primary");
        patientSearchButton.classList.remove("btn-outline-primary");
      } else {
        // patientIdContainer.classList.add("d-none");
        patientIdContainer.classList.add("opacity-0");
        patientSearchButton.classList.add("btn-outline-primary");
        patientSearchButton.classList.remove("btn-primary");
      }
    }
  }

  removePatientId(event) {
    this.patientIdTarget.value = "";
    setTimeout(() => {
      if (this.patientIdTarget && this.patientIdTarget.tomselect) {
        this.patientIdTarget.tomselect.clear();
        this.patientIdTarget.tomselect.clearOptions();
        this.patientIdTarget.tomselect.sync();
      }
    }, 400);
  }

  async patientChange(event) {
    let value = event.target.value;

    if (value && value != "") {
      if (this.hasLoadingTarget) {
        this.loadingTarget.classList.remove("d-none");
      }

      const response = await get(`/patients/${value}.json`, {
        responseKind: "json",
      });
      if (response.ok) {
        const body = await response.json;
        if (this.hasPatientCodeTarget) {
          this.patientCodeTarget.value = body.code;
        }
        if (this.hasPatientFirstNameTarget) {
          this.patientFirstNameTarget.value = body.first_name;
        }
        if (this.hasPatientLastNameTarget) {
          this.patientLastNameTarget.value = body.last_name;
        }
        console.log(body);
      }

      if (this.hasPatientIdContainerTarget) {
        let patientIdContainer = this.patientIdContainerTarget;
        let patientSearchButton = this.patientSearchButtonTarget;
        patientIdContainer.classList.add("opacity-0");
        patientSearchButton.classList.add("btn-outline-primary");
        patientSearchButton.classList.remove("btn-primary");
      }
      if (this.hasLoadingTarget) {
        this.loadingTarget.classList.add("d-none");
      }
    }
  }

  async patientChangeOld(event) {
    let params = new URLSearchParams();
    params.append(this.paramValue, event.target.selectedOptions[0].value);
    params.append("target", this.selectTarget.id);

    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.remove("d-none");
    }

    await get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream",
    });

    setTimeout(() => {
      if (this.selectTarget && this.selectTarget.tomselect) {
        this.selectTarget.tomselect.clear();
        this.selectTarget.tomselect.clearOptions();
        this.selectTarget.tomselect.sync();
      }
      if (this.hasLoadingTarget) {
        this.loadingTarget.classList.add("d-none");
      }
    }, 400);
  }
}
